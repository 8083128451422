import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/prodocs-logo.png';
import {Navbar, Nav, NavDropdown, MenuItem} from 'react-bootstrap';
import { FaThList,FaCheck,FaPencilAlt, FaCog, FaMoneyCheck, FaTrashAlt, FaPlus, FaRegFile, FaQuestionCircle, FaTruck, FaCalculator, FaWindowClose } from "react-icons/fa";
import styled from 'styled-components';
import { Accordion, Card, Button, Table, Row, Col } from 'react-bootstrap';
import {Link} from 'gatsby';
import EST from '../../assets/est.svg';
import WO from "../../assets/wo.svg";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby"
import config from '../../aws-exports';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { removeTemplate, elasticSearchDeleteDocOrTemp ,saveTemplateAs,elasticSearchUpdateDocOrTemp} from '../../graphql/queries';
import ExitModal from '../../Components/Modals/ExitModal';
import Cancel from '../../assets/cross.svg'
import Modal from 'react-bootstrap/Modal';
import CheckBox from '../../assets/checkmark3.svg';
import Loader from "./../../assets/loading.gif";
import GeneralPopup from '../Modals/GeneralPopup'
import SaveAsTempModal from '../../Components/Modals/SaveAsTempModal';
import  secureLocalStorage  from  "react-secure-storage";
import Sync from "../../assets/sync.gif";
import TemplateSavedPopup from '../Modals/TemplateSavedPopup';
export default function TemplateNavbar(props) {
    const {consoleLog} = require('../commonFunctions.js');
    const { url_params, ...restProps } = props; 
    consoleLog("TemplateNavbar props: ", props);
    const [templateInfo, setDocType] = React.useState(useSelector((state) => state.templateInfo));
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [activeNavBar, setActiveNavBar] = React.useState("line_items");
    const [modalShow, setModalShow] = React.useState(false);
    const updatedStructure = useSelector((state) => state.templateStructure);
    const [showDeleteModal, setShowDeleteModal] =  React.useState(false);

    const [synching, setSynching] = useState(false);
    const showSynching = async () => {
        setSynching(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));  // Simulate save delay
        setSynching(false);
    };

    function onClickTemplateSetting(){
        consoleLog("onClickTemplateSetting", "()");
        props.showSettingOption("template_settings");
        setActiveNavBar("template_settings");
    }
    function onClickDocDetail() {
        consoleLog("onClickDocDetail", props);
        props.showDocDetail("doc_details");
        setActiveNavBar("doc_details");
      }
    function onClickLineItems(){
        consoleLog("onClickLineItems", "()");
        props.showLineItem("line_items");
        setActiveNavBar("line_items");
    }

    const ScrollToTaxes = () => {
        consoleLog("doc_type_id: ", props.doc_type_id);
        consoleLog("template id to save: ", templateInfo.template_id);
        consoleLog("DOC_ID: ", props.doc_id);
        onClickLineItems();
        props.set_show_taxes_and_markup(true);

        // navigate(
        //     "/template?template_id=" + templateInfo.template_id + "&&doc_type_id=" + props.doc_type_id + "&&doc_id" +  props.doc_id + "#taxes"
        //   );

    }

    
    const [editTitleBox , setEditTitleBox] = useState(false)
    async function handleUpdateTemplateName(){
        // templateInfo.name
        console.log(props.templateName,"templateName");
        setDocType((prevState) => ({
            ...prevState,
            name: props.templateName
        }));
        setEditTitleBox(false);
        props.saveTemplate();
    }

    const redirectionHandel = () =>{
        consoleLog("props.modalShow2", props.modalShow2);
        props.setModalShow2(false);
        // navigate(`/template?template_id=${templateInfo.template_id}`)
    }

    const handleExit = () => {
        // !save ? setModalShow(true) : navigate('/templates?doc_type_id=1');
        setModalShow(true);
    }

    function saveEstimate(){
        consoleLog('saveEstimate>',stData);
    }
    
    async function deleteTemplate() {
        consoleLog("deleteTemplate", templateInfo);
        props.setLoading(true);
        showSynching();
        const deleteTemp = await API.graphql({  // delete from db
            query: removeTemplate,
            variables: {
                template_id: templateInfo.template_id
            }
        });
        consoleLog("deleteTemplate deleteTemp", deleteTemp);
        showSynching();
        const deleteTempElastic = await API.graphql({ // delete from elasticsearch
            query: elasticSearchDeleteDocOrTemp,
            variables: {
              id: templateInfo.template_id,
              index: 'prodocs-templates'
            }
        });

        let es_result = JSON.parse(deleteTempElastic.data.elasticSearchDeleteDocOrTemp);
        consoleLog('deleteTemplate handle_es_down status>', es_result.status);
        if(es_result.status != 200){
            consoleLog('deleteTemplate handle_es_down status>', es_result.status);
            let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.temps;
            es_down_docs.push({template_id : templateInfo.template_id, es_down_flag : '3'}); //3 for delete
            consoleLog('deleteTemplate handle_es_down es_down_docs>>>', es_down_docs);
            let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
            consoleLog('deleteTemplate handle_es_down temp_docs_temp>>>', temp_docs_temp);

            temp_docs_temp.body.temps = es_down_docs;
            consoleLog('deleteTemplate handle_es_down temp_docs_temp final>>>', temp_docs_temp);
            secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
        }

        props.setLoading(false);
        consoleLog("deleteTemplate deleteTempElastic", deleteTempElastic);
        navigate('/templates?doc_type_id='+templateInfo.doc_type_id);
    }

    return (
        <ContentContainer>
            {
                showDeleteModal &&
                <GeneralPopup 
                titleText="Are You Sure?"
                bodyText={<>Are you sure you want to <b>delete</b> this template?</>}
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                confirm={deleteTemplate}
                />
            }
            {
                props.modalShow3 &&
                <SaveAsTempModal
                show={props.modalShow3}
                setModalShow={props.setModalShow3}
                doc_type_id={templateInfo.doc_type_id}
                />
            }
            <Row>
                <div className="first_row">


                            <div className="left-side ">
                                <Link to="/document">
                                    <img style={{width:'120px'}} className="ml-3" src={Logo} alt="Logo"/>
                                </Link>

                                {/* { (templateInfo.doc_type_id == 1) &&
                                    <span className="temp_icon mr-3" style={{background: "#f7941d"}}> <FaCalculator/></span>
                                }

                                { (templateInfo.doc_type_id == 2) &&
                                    <span className="temp_icon mr-3" style={{background: "#9ac8f1"}}> <FaTruck/></span>
                                } */}

                            </div>
                            <div className="right-side">
                                {/* <Button className="saveTempBtn" onClick={ () => saveTemplate() }>
                                    Save Template
                                </Button>
                                <p className="title m-0 ml-3">Save As...</p>
                                <p className="title m-0 ml-5">Exit <span><FaWindowClose/></span></p> */}

                                <a
                                 onClick={handleExit}
                                  className="title hover-state m-0"
                                  style={{fontFamily:'Rajdhani', fontWeight: '600', textDecoration:'none', color:'#212E33', fontSize:'16px'}} >
                                  <span className="pr-2"> <img style={{width:"26px" }} src={Cancel} alt=""/> </span>
                                  EXIT
                                </a>
                            </div>

                </div>



                <div>
                    {
                        modalShow &&
                        <ExitModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            setModalShow={setModalShow}
                            redirect={'/templates?doc_type_id='+templateInfo.doc_type_id}
                        />
                    }
                </div>





                <div className="second_row">
                    <div className=" wrap p-0">
                        <Row className="m-0 sec_row_info w-100" style={{minHeight:'70px'}}>

                                    <div className="left-side2">
                                         <span className="temp_icon mr-3"> <img src={ templateInfo.doc_type_id == 1 ? EST : WO } alt=""/> </span>
                                         <p className="title m-0">{templateInfo.name}</p>
                                         {editTitleBox == true ? 
                                            <div className="doc_lbl w-50">
                                            <input type="text" name="doc_label" value={props.templateName} onInput={(e)=>{props.setTemplateName(e.target.value)}} className="setting-input"/>
                                            <span className="cursor-pointer ml-3 mt-2" onClick={()=>{handleUpdateTemplateName()}}><FaCheck /></span>
                                            </div>
                                            :<span className="cursor-pointer" onClick={() => {setEditTitleBox(true);props.setTemplateName(props.templateName || ""); } }> <FaPencilAlt /></span>}
                                    </div>
                                    <div className="right-side right-side2">
                                    {
                                        ((typeof props.doc_id === 'undefined' && (templateInfo.doc_type_id === 1 || templateInfo.doc_type_id === 2)) || props.url_params?.is_template) && (
                                            <Button 
                                                className={props.loading ? (templateInfo.doc_type_id === 1 ? 'saveTempBtn saveTempBtnDis' : 'saveTempBtnx saveTempBtnDis') : (templateInfo.doc_type_id === 1 ? 'saveTempBtn' : 'saveTempBtnx')} 
                                                onClick={() => props.saveTemplate()}
                                            >
                                                Save Template
                                            </Button>
                                        )
                                    }
                                    </div>
                                    {
                                        (props.save && props.modalShow2) ?
                                        <TemplateSavedPopup 
                                            onHide={() => props.setModalShow2(false)}
                                            onRedirect={redirectionHandel}
                                            modalShow2={props.modalShow2}
                                            />:<div></div>
                                    }
                        </Row>
                    </div>
                    <div className="wrap custom-padding-dovNav" >
                        {/* <Row className="m-0 flux"> */}
                            <div className="custom-width pl-4 ml-2">
                                <div className="left-side-icons mr-7">
                                    <p className={`title s_nav_item ${ (activeNavBar == "line_items") ? "s_nav_item_active" : ""}` } style={{cursor:'pointer'}} onClick={onClickLineItems}>  Line Items</p>
                                    { ((typeof props.doc_id == 'undefined' && templateInfo.doc_type_id == 1 ) || props.url_params?.is_template)&&
                                        <a className="title ml-5 s_nav_item" href="#taxes"  style={{cursor:'pointer', textDecoration:'none'}} onClick={ScrollToTaxes}> Taxes, Markups, & Financing</a>
                                    }
                                    { (props.doc_id > 0 && !props.url_params?.is_template) &&
                                    <>
                                        <p
                                            className={`title ml-lg-5 ml-md-4 ml-2 s_nav_item ${
                                            activeNavBar == "doc_details" ? "s_nav_item_active" : ""
                                            }`}
                                            style={{ cursor: "pointer" }}
                                            onClick={onClickDocDetail}
                                        >
                                            {" "}
                                            Details
                                        </p>
                                        <p
                                            className={`title ml-5 s_nav_item ${
                                            activeNavBar == "doc_settings" ? "s_nav_item_active" : ""
                                            }`}
                                            style={{ cursor: "pointer" }}
                                            onClick={onClickTemplateSetting}
                                        >
                                            {" "}
                                            Doc Settings
                                        </p>
                                        <p className={`title ml-5 s_nav_item ${ (activeNavBar == "template_settings") ? "s_nav_item_active" : ""}`} style={{cursor:'pointer'}} onClick={onClickTemplateSetting}> Templates</p>
                                    </>
                                    }
                                    { (typeof props.doc_id == 'undefined' || props.url_params?.is_template) &&
                                        <p
                                            className={`title ml-5 s_nav_item ${
                                            activeNavBar == "doc_settings" ? "s_nav_item_active" : ""
                                            }`}
                                            style={{ cursor: "pointer" }}
                                            onClick={onClickTemplateSetting}
                                        >
                                            {" "}
                                            Template Settings
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="custom-width_2 ">
                                { (props.doc_id > 0 && !props.url_params?.is_template) &&
                                <>
                                    <p className="m-0 s_nav_item2 ">Save Document</p>
                                    <p className=" mb-0 ml-5 s_nav_item2"> Save As Template</p>
                                    <Nav.Link href="/newTemplate" className="title ml-5 s_nav_item2" target="_blank">
                                    <p className="m-0 s_nav_item2" onClick={deleteTemplate}>Delete</p>
                                    </Nav.Link>
                                </>
                                }
                                { (typeof props.doc_id == 'undefined' || props.url_params?.is_template ==1) &&
                                <>
                                    <p style={{cursor:"pointer"}} className="m-0 s_nav_item2 " onClick={props.saveTemplateAsModal}>Save As...</p>
                                    <p style={{cursor:"pointer"}} className="mb-0 ml-5 s_nav_item2" onClick={()=>{setShowDeleteModal(true)}}> Delete</p>
                                </>
                                }
                                {/* <p className=" mb-0 mx-3 s_nav_item2">  Help</p> */}
                            </div>
                        {/* </Row> */}
                    </div>
                </div>
            </Row>
            {synching && <img className="sync" src={Sync} />}
        </ContentContainer>
    )
}

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
        // background-color: rgba(255,255,255, 0.5);
        // transition: background-color 300ms;
    }
`


const ContentContainer = styled.div`
    background: #f7f9fa;

    width:100%;
    box-shadow: 0 0 6px #000000;
    z-index:99;

    .flux{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    .doc_lbl{
        display: flex;
    }
    .setting-input{
        height:40px;
        width:100%;
        outline:none;
        padding:10px;
        border-radius:4px;
        border:1px solid #B4BEBF;
        color:#212E33;
        font-style: normal;
        font-size:14px;
    }
    .wrap {
        position: relative;
        margin: auto;
        width: 100%;
        min-width: 320px;
        box-sizing: border-box;
        padding-left: 0px;
        padding-right: 0px;
    }
    .sec_row_info{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:0 10px;
            /* @media(max-width:768px){
                padding:0 45px;
            } */

        }
        .left-side2{
            width:50%;
            display:flex;
            align-items:center;

            // justify-content:space-between;
            @media(max-width:600px){

                padding:3px;
            }
            img{

                height:auto;
            }
            .title{
                font-size:28px;
                color:#212E33;
                font-family:Roboto;
            }
            .title-ti{
            font-size:28px;
            color:#212E33;
            font-family:Roboto;
        }
        }
        .right-side2{
            width:50%;
            display:flex;
            align-items:center;
            justify-content:flex-end;

        }
        .right-side{
        display:flex;
        align-items:center;
        padding-right:10px;
        // justify-content:space-around;

        @media(max-width:600px){

            justify-content:center;
            padding:3px 10px;
        }
        .saveTempBtn{
            height: 44px;
            width: 180px;
            border-radius: 3px;
            background-color: #06D6A0 !important;
            outline:none;
            border:none;
            color: #212E33;
            font-family: Rajdhani;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.6px;
            &:hover {
                background-color: #d6e1e5 !important ;
            }
        }
        .saveTempBtnx{
            height: 44px;
            width: 180px;
            border-radius: 3px;
            background-color: #4cadff !important;
            outline:none;
            border:none;
            color: #212E33;
            font-family: Rajdhani;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.6px;
            &:hover {
                background-color: #d6e1e5 !important;
            }
        }
        .saveTempBtnDis{
            background-color: #d6e1e5 !important;
        }
    }
    .text-black{
        color: #333;
    }

    .temp_icon{
        font-size: 3rem;
        padding: 0.1rem 0.5rem;
        border-radius: 5px;
    }


    .s_nav_item{
        color:#212E33;
        font-size:16px;
        font-family:Rajdhani;
        font-weight:600 !important;
        margin-bottom:0px !important;

        &:hover  {
            color: #F7941C;
          }
    }
    .s_nav_item_active{
        color: #F7941C;
    }


    .mr-7{
        margin-left: -7px;
    }

    .first_row{
        width:100%;
        min-height:60px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding: 5px 60px;

        @media(max-width:600px){
            width:100%;
            padding:5px 20px;
        }
        .left-side{

            display:flex;
            align-items:center;
            // justify-content:space-between;
            @media(max-width:600px){
                padding:3px;
            }
            img{
                width:100px;
                height:auto;
            }
            .title{
                font-size:18px;
                font-weight:500;
            }

        }
        .right-side{

        display:flex;
        align-items:center;
        justify-content:flex-end;
        padding-right:10px;
        // justify-content:space-around;

        @media(max-width:600px){
            padding-left:0px !important;
            justify-content:center;
            padding:3px 10px;
        }

        .hover-state {
            &:hover  {
                color: #F7941C !important;
              }
        }
        .saveTempBtn{
            color:#212E33;
            background-color: #06D6A0 !important;
            outline:none;
            border:none;
            // border-radius:15px;
            font-size: small;
            padding: 8px 16px;
        }
    }
    }
    .custom-padding-dovNav{
            padding-top:60px;
            display:flex;
            align-items:center;
            justify-content:space-between;

            @media(max-width:600px){
                flex-direction:column;
            }
            /* @media(min-width:1400px){
                padding:60px 0px 0 0px;
            }
            @media(max-width:1024px){
                padding:60px 40px 0 40px;
            } */
        }

    .second_row{
        width:100%;
        min-height:45px;
        display:flex;
        align-items:center;
        padding:0 85px;
        flex-direction:column;
        @media (max-width: 1024px) {
            padding: 0 50px;
        }

        @media (max-width: 600px) {
        width: 100%;
        padding: 0 10px;
        flex-direction: column;
        }
        .custom-width{
            /* width:71%; */
            min-height:45px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            @media(max-width:600px){
            width:100%;
            flex-direction:column;
        }
        .left-side-icons{
            /* width:72%; */
            display:flex;
            align-items:center;
            // justify-content:space-evenly;
            @media(max-width:600px){
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
            }

            .title{

                font-weight:500;
                margin:0 5px;

                @media(max-width:600px){
                margin:2px !important;
                font-size:13px;
                    }
            }
        }
        .uper-left{
            margin-right:5px;
            .title{
                margin: 0 5px;
                font-weight:500;
            }
        }
    }
    .custom-width_2{
        /* width:25%; */
        min-height:45px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:0 10px;
        @media(max-width:600px){
            width:83%;
            justify-content:flex-start;
        }

        .s_nav_item2{
        color:#212E33;
        font-size:16px;
        font-family:Rajdhani;
        font-weight:600 !important;
        margin-bottom:0px !important;

        &:hover  {
            color: #F7941C;
          }
        }
    }
}

`

const Btn = styled.div`
    min-width:100%;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    color:#ffffff;
    border-radius:20px;
    background:red;
    padding:20px;
    font-size:17px;
    font-weight:500;
    text-decoration:none;
`
