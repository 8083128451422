import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import CheckBox from '../../assets/checkmark3.svg';

const TemplateSavedPopup = ({ onHide, onRedirect, modalShow2}) => {
  if (!modalShow2) {
    return null; // Render nothing if the modal conditions are not met
  }

  return (
    <Modal
      show={modalShow2}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ opacity: '1' }}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="text-center my-3">
            <img src={CheckBox} style={{ width: '60px', height: 'auto' }} alt="" />
          </div>
          <p className="modal-title-x mb-0 mt-2">Your Template Has Been Saved</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button className="modal-btn-x" onClick={onRedirect}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateSavedPopup;