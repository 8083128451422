import React, { useState, useEffect } from "react";
import useDebounce from "./searchDelay";

const LineItemSearchComponent = ({
  handleKeyDown,
  handleLineItemChangeText,
  isDisabled,
}) => {
  const [lineItemSearchText, setLineItemSearchText] = useState("");
  const debouncedTerm = useDebounce(lineItemSearchText, 500);

  // Trigger API call when debouncedTerm changes
  useEffect(() => {
    if (debouncedTerm.trim()) {
      handleLineItemChangeText(debouncedTerm);
    }
  }, [debouncedTerm]);

  // Handle input change
  const onInputChange = (e) => {
    setLineItemSearchText(e.target.value);
  };

  // Clear search input on component unmount
  useEffect(() => {
    return () => setLineItemSearchText("");
  }, []);

  return (
    <input
      type="text"
      onChange={onInputChange}
      onKeyDown={handleKeyDown}
      disabled={isDisabled}
      className="w-100 custom-input-x"
      placeholder="Enter Line Item Name"
      value={lineItemSearchText}
    />
  );
};

export default LineItemSearchComponent;